import React, { useState, useEffect } from "react";
import axios from "axios";
import {  FaEdit, FaTrashAlt } from "react-icons/fa"; // Import trash icon from react-icons
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import config from "../config";
import { toast } from "react-toastify"; // Import toastify
import "react-toastify/dist/ReactToastify.css"; // Import the necessary CSS

const StockTable = () => {
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    stockProductId: "",
    stockRecordId: "",
    quantity: "",
    currentTotalQuantity: "",
    date: "",
  });

  // For delete confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    const fetchStockData = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem("Dealertoken");
        if (!token) {
          setError("Token not found.");
          return;
        }
        const response = await axios.get(
          `${config.BASE_URL}/api/dealer/stock`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data) {
          setStockData(response.data);
        } else {
          setError("No data available.");
        }
      } catch (error) {
        setError("Error fetching stock data.");
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, []);

  const filteredStockData = stockData.filter((item) => {
    const brandName = item.brandName?.toLowerCase() || "";
    const modelName = item.modelName?.toLowerCase() || "";
    const query = searchQuery.toLowerCase();

    return brandName.includes(query) || modelName.includes(query);
  });

  const handleEditClick = (item, history) => {
    setEditMode(true);
    setEditData({
      stockProductId: item._id,
      stockRecordId: history._id,
      quantity: history.quantity,
      currentTotalQuantity: history.currentTotalQuantity,
      date: history.date,
    });
  };

  const handleInputChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editData.stockProductId || !editData.stockRecordId) {
      console.error("Invalid data: Missing IDs");
      return;
    }

    try {
      const token = localStorage.getItem("Dealertoken");
      const data = JSON.stringify({
        quantity: parseInt(editData.quantity),
        currentTotalQuantity: parseInt(editData.currentTotalQuantity),
        date: editData.date,
      });

      const axiosConfig = {
        method: "patch",
        url: `${config.BASE_URL}/api/dealer/stockProduct/${editData.stockProductId}/stockRecord/${editData.stockRecordId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios.request(axiosConfig);
      console.log("Update successful:", response.data);
      window.location.reload();
      setEditMode(false);
    } catch (error) {
      console.error(
        "Error updating record:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Delete stock history handler
  const handleDeleteClick = (item, history) => {
    setDeleteData({ stockProductId: item._id, stockRecordId: history._id });
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const token = localStorage.getItem("Dealertoken");
      const response = await axios.delete(
        `${config.BASE_URL}/api/dealer/stockProduct/${deleteData.stockProductId}/history/${deleteData.stockRecordId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Delete successful:", response.data);
      // Remove deleted item from the state
      setStockData(
        stockData.filter((item) => item._id !== deleteData.stockProductId)
      );
      setIsModalOpen(false);
      // Show success toast and refresh the page
      toast.success("Stock history record deleted successfully!");
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error(
        "Error deleting record:",
        error.response ? error.response.data : error.message
      );
      setIsModalOpen(false);
      toast.error("Error deleting the stock history record.");
    }
  };

  return (
    <>
      <TopNav />
      <div className="p-6 bg-white shadow-md rounded-lg mt-16 mb-16">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search by brand or model"
            className="p-2 border border-gray-300 rounded-md w-full"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {loading && (
          <div className="flex items-center justify-center h-screen">
            <div className="w-16 h-16 border-4 border-teal-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          </div>
        )}
        {error && !loading && <p className="text-red-500">{error}</p>}
        {!loading && !error && stockData.length === 0 && (
          <p className="text-yellow-500">No data available.</p>
        )}
        {!loading && !error && stockData.length > 0 && (
          <>
            {filteredStockData.length === 0 ? (
              <p className="text-gray-500">No results found.</p>
            ) : (
              filteredStockData.map((item, index) => (
                <div key={index} className="mb-8">
                  <div className="mb-4">
                    <h1 className="text-2xl font-bold mb-2">
                      {item.brandName || "N/A"} - {item.modelName || "N/A"}
                    </h1>
                    <p className="text-lg mb-2">
                      Price: ₹{item.price?.toFixed(2) || "0.00"}
                    </p>
                    <p className="text-lg mb-2">
                      Total Quantity: &nbsp;
                      <span
                        className={
                          item.totalQuantity >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        {item.totalQuantity || 0}
                      </span>
                    </p>
                    <p className="text-lg mb-2">
                      Total Amount: &nbsp;
                      <span
                        className={
                          item.totalAmount >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        ₹{item.totalAmount?.toFixed(2) || "0.00"}
                      </span>
                    </p>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="w-full border-collapse bg-gray-100 rounded-lg">
                      <thead>
                        <tr className="bg-teal-600 text-white">
                          <th className="p-3 border-b">S.No</th>
                          <th className="p-3 border-b">Date</th>
                          <th className="p-3 border-b">Quantity</th>
                          <th className="p-3 border-b">Stock</th>
                          <th className="p-3 border-b">Amount</th>
                          <th className="p-3 border-b">Edit</th>
                          <th className="p-3 border-b">Delete</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {item.stockHistory && item.stockHistory.length > 0 ? (
  item.stockHistory.map((history, historyIndex) => {
    const quantityColor = history.quantity >= 0 ? "text-green-500" : "text-red-500";
    const quantitySign = history.quantity >= 0 ? "+" : "-";
    const amount = history.quantity * item.price;
    const amountClass = amount >= 0 ? "text-green-500" : "text-red-500"; 

                              return (
                                <tr
                                  key={history._id}
                                  className="hover:bg-gray-200"
                                >
                                  <td className="p-3 border-b">
                                    {historyIndex + 1}
                                  </td>
                                  <td className="p-3 border-b">
                                    {editMode &&
                                    editData.stockRecordId === history._id ? (
                                      <input
                                        type="date"
                                        name="date"
                                        value={editData.date.split("T")[0]}
                                        onChange={handleInputChange}
                                        className="p-1 border rounded"
                                      />
                                    ) : (
                                      new Date(
                                        history.date
                                      ).toLocaleDateString()
                                    )}
                                  </td>
                                  <td
                                    className={`p-3 border-b ${quantityColor}`}
                                  >
                                    {editMode &&
                                    editData.stockRecordId === history._id ? (
                                      <input
                                        type="number"
                                        name="quantity"
                                        value={editData.quantity}
                                        onChange={handleInputChange}
                                        className="p-1 border rounded"
                                      />
                                    ) : (
                                      `${quantitySign}${Math.abs(
                                        history.quantity
                                      )}`
                                    )}
                                  </td>
                                  <td className="p-3 border-b">
                                    {editMode &&
                                    editData.stockRecordId === history._id ? (
                                      <input
                                        type="number"
                                        name="currentTotalQuantity"
                                        value={editData.currentTotalQuantity}
                                        onChange={handleInputChange}
                                        className="p-1 border rounded"
                                        disabled
                                      />
                                    ) : (
                                      history.currentTotalQuantity
                                    )}
                                  </td>
                                  <td className={`p-3 border-b ${amountClass}`}>
                                    ₹{Math.abs(amount).toFixed(2)}
                                  </td>
                                  <td className="p-3 border-b">
                                    {editMode &&
                                    editData.stockRecordId === history._id ? (
                                      <button
                                        onClick={handleSubmit}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                      >
                                        Save
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          handleEditClick(item, history)
                                        }
                                        className="bg-transparent hover:bg-gray-200 text-teal-700 font-semibold py-1 px-2 border border-teal-500 rounded"
                                      >
                                          <FaEdit />
                                      </button>
                                    )}
                                  </td>
                                  <td className="p-3 border-b">
                                    <button
                                      onClick={() =>
                                        handleDeleteClick(item, history)
                                      }
                                      className="bg-transparent hover:bg-red-200 text-red-500 font-semibold py-1 px-2 border border-red-500 rounded"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="p-3 text-center text-gray-500"
                            >
                              No stock history available.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete this stock history record?</p>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <BottomNav />
    </>
  );
};

export default StockTable;

